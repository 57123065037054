<template>
	<div>
		<b-overlay :show="isLoading">
			<b-card class="p-1" no-body>
				<b-row>
					<b-col class="d-flex justify-content-end">
						<b-button
							class="d-inline text-nowrap"
							variant="primary"
							@click="creationModal.show = !creationModal.show"
						>
							Agregar tarea
						</b-button>
					</b-col>
				</b-row>
			</b-card>
			<b-card no-body>
				<b-table-simple sticky-header="60vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Nombre</b-th>
							<b-th>Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="row in rows" :key="row.id">
							<b-td>{{ row.name }}</b-td>
							<b-td>
								<div class="d-flex justify-content-center" style="gap: 0.5rem">
									<b-button size="sm" variant="flat-warning" @click="openEdit(row)">
										<feather-icon icon="EditIcon" />
									</b-button>
									<b-button size="sm" variant="flat-danger" @click="remove(row)">
										<feather-icon icon="TrashIcon" />
									</b-button>
								</div>
							</b-td>
						</b-tr>
						<b-tr v-if="rows.length == 0">
							<b-td colspan="4">
								<p class="mb-0">No hay registros por mostrar</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-card>
		</b-overlay>
		<b-modal
			centered
			v-model="creationModal.show"
			:title="`${creationModal.editing ? 'Actualizando' : 'Creando nueva'} tarea`"
			@hidden="handleHidden"
		>
			<b-overlay :show="creationModal.loading">
				<ValidationObserver ref="form">
					<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
						<b-form-group label="Nombre">
							<b-form-input
								:class="{ 'border-danger': errors[0] }"
								placeholder="Ingrese el nombre"
								v-model="creationModal.name"
							/>
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="info" @click="edit" v-if="creationModal.editing">Actualizar</b-button>
				<b-button variant="success" @click="create" v-else>Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import tasksService from "@/services/tasks.service.js"

export default {
	props: {
		action: String,
	},
	data: () => ({
		isLoading: false,
		rows: [],
		creationModal: {
			show: false,
			loading: false,
			name: null,
			oldName: null,
			id: null,
			editing: false,
		},
	}),
	methods: {
		handleHidden() {
			this.creationModal = { show: false, loading: false }
			this.$emit("actionDone")
		},
		clearCreation() {
			this.creationModal = {
				show: false,
				loading: false,
				name: null,
				oldName: null,
				id: null,
				editing: false,
			}
		},
		async getRows() {
			this.isLoading = true
			const { data } = await tasksService.getTasksBrain()
			this.rows = data
			this.isLoading = false
		},
		async create() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se creara una nueva tarea`,
			})
			if (!isConfirmed) return
			this.creationModal.loading = true
			await tasksService.insertTaskBrain({ name: this.creationModal.name })
			this.creationModal.loading = false
			this.clearCreation()
			this.getRows()
		},
		openEdit(row) {
			this.creationModal = {
				show: true,
				loading: false,
				name: row.name,
				oldName: row.name,
				id: row.id,
				editing: true,
			}
		},
		async edit() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se actualizara la tarea: ${this.creationModal.oldName}`,
			})
			if (!isConfirmed) return
			this.creationModal.loading = true
			await tasksService.updateTaskBrain({ task_id: this.creationModal.id, name: this.creationModal.name })
			this.creationModal.loading = false
			this.clearCreation()
			this.getRows()
		},

		async remove(row) {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.isLoading = true
			try {
				await tasksService.deleteTaskBrain({ task_id: row.id })
			} catch (error) {
				this.isLoading = false
				return
			}
			this.getRows()
			this.isLoading = false
		},
	},
	created() {
		this.getRows()
	},
	watch: {
		action(val) {
			if (val == "create-quality") {
				this.creationModal.active = !this.creationModal.active
			}
		},
	},
}
</script>
